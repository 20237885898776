import React from 'react'
import SEO from 'src/components/seo'
import LayoutBlank from '../components/layout-blank/layout-blank'
import NotFound from '../components/not-found/not-found'

const NotFoundPage = () => (
  <LayoutBlank isMini={true}>
    <SEO title="404: Not found" />
    <NotFound />
  </LayoutBlank>
)

export default NotFoundPage
