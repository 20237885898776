import PropTypes from 'prop-types'
import React from 'react'
import 'src/assets/scss/app.scss'
import Footer from 'src/components/footer/footer'
import style from './layout-blank.module.scss'

const LayoutBlank = ({ children, isMini }) => {
  return (
    <>
      <div className={`area--page ${style.page}`}>
        <div
          className={`u-wrapper${isMini ? ' u-wrapper--mini' : ''} ${
            style.wrapper
          }`}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

LayoutBlank.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutBlank
