import { Link } from 'gatsby'
import React from 'react'
import empty from 'src/assets/images/empty.svg'
import style from './not-found.module.scss'

const NotFound = ({ data }) => (
  <div className={`${style.content}`}>
    <img
      className={style.img}
      src={empty}
      alt="Erreur 404 : contenu non trouvé"
    />
    <h1 className={style.title}>Contenu non trouvé</h1>
    <p className="content">
      Vous êtes perdus ? Dans ce cas, le mieux est de{' '}
      <Link to="/">revenir au début</Link>.
      <br />
      Si vous avez remarqué un bug n'hésitez pas à m'en faire part via la{' '}
      <Link to="/contact/">page contact</Link>.
    </p>
  </div>
)

export default NotFound
